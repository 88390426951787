<script lang="tsx">
import type { SlotsType } from 'vue'
import type { ScssBreakpoint } from '@core-types/scss'
import type { CollapseData } from '../../../composables/collapse'

export type BaseUtilCollapseButtonProps<T> = {
    modelValue?: CollapseData
    expandedBreakpoint?: ScssBreakpoint
}

type BaseUtilCollapseButtonSlots<T> = {
    default: {}
    leading: {
        isExpanded: boolean
        hideClass: string | undefined
    }
    trailing: {
        isExpanded: boolean
        hideClass: string | undefined
    }
}

type ComponentOptions = {}

export function defineComponentBaseUtilCollapseButton<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUtilCollapseButtonProps<T>, BaseUtilCollapseButtonSlots<T>>) {
    return defineComponent(
        (props: BaseUtilCollapseButtonProps<T>, ctx) => {
            const nuxtApp = useNuxtApp()

            const modelValue = computed({
                get() {
                    return props.modelValue
                },
                set(val: CollapseData) {
                    ctx.emit('update:modelValue', val)
                },
            })

            const expandedBreakpoint = props.expandedBreakpoint ?? modelValue.value?.expandedBreakpoint

            const _isOverExpandedBreakpoint = expandedBreakpoint ? useScssBreakpoints().greaterOrEqual(expandedBreakpoint) : null
            const isOverExpandedBreakpoint = ref<boolean>(false)    // mismatch safe value
            if (_isOverExpandedBreakpoint !== null) {
                watch(_isOverExpandedBreakpoint, (val) => {
                    isOverExpandedBreakpoint.value = val
                })

                onMounted(() => {
                    isOverExpandedBreakpoint.value = _isOverExpandedBreakpoint?.value ?? false
                })
            }

            const hideClass: string | undefined = ({
                'xs': 'xs:hidden',
                'sm': 'sm:hidden',
                'md': 'md:hidden',
                'lg': 'lg:hidden',
                'xl': 'xl:hidden',
                'xxl': 'xxl:hidden',
            } satisfies Record<ScssBreakpoint, string>)[expandedBreakpoint as ScssBreakpoint]

            function toggleExpanded() {
                if (_isOverExpandedBreakpoint?.value || !modelValue.value) return
                modelValue.value = {
                    ...modelValue.value,
                    isExpanded: !modelValue.value.isExpanded,
                }
            }

            return () => (
                <button
                    class={['sim-c-btn', {
                        'sim-c-btn--fake': isOverExpandedBreakpoint.value,
                    }]}
                    type="button"
                    aria-expanded={isOverExpandedBreakpoint.value ? undefined : modelValue.value?.isExpanded}
                    aria-controls={isOverExpandedBreakpoint.value ? undefined : modelValue.value?.id}
                    disabled={isOverExpandedBreakpoint.value}
                    onClick={toggleExpanded}
                >
                    <span class="sim-c-btn__content">
                        {
                            // LEADING SLOT
                            (ctx.slots.leading !== undefined || options?.slots?.leading) && (
                                <span aria-hidden="true">
                                    {renderSlot(ctx.slots.leading, options?.slots?.leading, {
                                        isExpanded: modelValue.value?.isExpanded ?? false,
                                        hideClass: hideClass,
                                    })}
                                </span>
                            )
                        }

                        {
                            // DEFAULT SLOT
                            renderSlot(ctx.slots.default, options?.slots?.default, {})
                        }

                        {
                            // TRAILING SLOT
                            (ctx.slots.trailing !== undefined || options?.slots?.trailing) && (
                                <span aria-hidden="true">
                                    {renderSlot(ctx.slots.trailing, options?.slots?.trailing, {
                                        isExpanded: modelValue.value?.isExpanded ?? false,
                                        hideClass: hideClass,
                                    })}
                                </span>
                            )
                        }
                    </span>
                </button>
            )
        },
        {
            props: {
                modelValue: {
                    type: Object as PropType<BaseUtilCollapseButtonProps<T>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? true,
                },
                expandedBreakpoint: {
                    type: String as PropType<BaseUtilCollapseButtonProps<T>['expandedBreakpoint']>,
                    default: options?.props?.expandedBreakpoint?.default,
                    required: options?.props?.expandedBreakpoint?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUtilCollapseButtonSlots<T>>,
            emits: {
                'update:modelValue': (val: CollapseData) => true,
            },
        }
    )
}

export default defineComponentBaseUtilCollapseButton()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUtilCollapseButton.scss" as *;

</style>
