import type { ScssBreakpoint } from '@core-types/scss'

interface UseCollapseOptions {
    defaultExpanded?: boolean
    expandedBreakpoint?: ScssBreakpoint
}

export interface CollapseData {
    isExpanded: boolean
    expandedBreakpoint: ScssBreakpoint | null
    /**
     * The id of the collapse component.
     */
    id: string
}

export function useCollapse(options?: Partial<UseCollapseOptions>) {
    const isExpanded = ref<boolean>(options?.defaultExpanded ?? false)
    const id = useId()

    if (import.meta.dev && !id) {
        errorLog('[useCollapse]: `useId` returned `undefined`. You\'re probably calling this composable outside of a component.')
    }

    const collapseData = computed<CollapseData>({
        get() {
            return {
                isExpanded: isExpanded.value,
                expandedBreakpoint: options?.expandedBreakpoint ?? null,
                id: id ?? '-',
            }
        },
        set(value: CollapseData) {
            isExpanded.value = value.isExpanded
        },
    })

    return {
        collapseData,
    }
}
