<script lang="tsx">
import { defineComponentBaseUtilCollapseButton } from '@core/app/components/base/util/BaseUtilCollapseButton.vue'
import { IconChevron } from '#components'

export default defineComponentBaseUtilCollapseButton({
    slots: {
        trailing: ({ isExpanded, hideClass }) => <IconChevron up={isExpanded} class={hideClass} size="xs" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUtilCollapseButton.scss" as *;

</style>
